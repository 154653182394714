import React, { Component } from 'react'
import { Tag, SkeletonText, TagSkeleton, Link, TooltipIcon, Icon, Tile, TooltipDefinition } from 'carbon-components-react'

import axios from 'axios'
import qS from 'query-string'

import 'carbon-components/scss/globals/scss/styles.scss'
import './App.css'

const instance = axios.create({
  baseURL: 'https://fruit-k8s.fruitice.fr/v1'
})
let location = qS.parse(window.location.search)

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      error: false,
      services: {},
      infos: null,
      globalCount: {
        up: 0,
        down: 0
      }
    }

    this.fetchServices()
    this.fetchInfos()
  }

  fetchServicesLater () {
    setTimeout(_ => { this.fetchServices() }, 10 * 1000)
  }

  fetchServices () {
    if (this.state.error) {
      this.setState({ loading: true })
    }
    instance.get('/services')
      .then(res => {
        let services = {}
        let globalCount = { up: 0, down: 0 }
        res.data.services.forEach(service => {
          if (location.ns && location.ns !== service.namespace) return
          if (services[service.namespace] === undefined) {
            services[service.namespace] = {
              deployments: [],
              dependencies: []
            }
          }
          if (service.type === 'deployment') {
            services[service.namespace].deployments.push(service)
          } else if (service.type === 'namespaceDependency') {
            services[service.namespace].dependencies.push(service)
          }
          service.pods.forEach(pod => {
            if (pod.status === 'Running') {
              globalCount.up++
            } else {
              globalCount.down++
            }
          })
        })

        this.setState({ services, globalCount, loading: false, error: false })
        this.fetchServicesLater()
      }).catch(_ => {
        this.setState({ error: true, loading: false })
        this.fetchServicesLater()
      })
  }

  fetchInfos () {
    instance.get('/info')
      .then(res => {
        this.setState({ infos: res.data })
      })
      .catch(_ => {
        this.fetchInfos()
      })
  }

  render () {
    return (
      <div>
        <div className='App'>
          <header className='App-header'>
            <div className='App-header_content'>
              <h1 className='App-title'>Fruit'ice</h1>
              <p className='App-intro'>Kubernetes cluster services status</p>
              <p className='App-subtitle'>
                {
                  this.state.infos
                    ? `${this.state.infos.gitVersion} (${this.state.infos.buildDate})`
                    : null
                }
              </p>
              <p className='App-subtitle'>
                { !this.state.loading && !this.state.error
                  ? <div>Up: {this.state.globalCount.up}, down: {this.state.globalCount.down}</div>
                  : null
                }
              </p>
            </div>
          </header>
          {
            this.state.loading ? <ListServicesSkeleton /> : (this.state.error ? <Errored /> : <ListServices services={this.state.services} />)
          }
        </div>
      </div>
    )
  }
}

class Errored extends Component {
  render () {
    return (
      <div className='App-content'>
        <article className='App-content_content'>
          Sorry, error when loading services...
        </article>
      </div>
    )
  }
}

class ListServicesSkeleton extends Component {
  render () {
    return (
      <div className='App-content'>
        <article className='App-content_content'>
          <div>
            <h2 className='App__demo-title'><SkeletonText width='140px' /></h2>
            <div className='namespace'>
              <Tile className='service bx--tile'>
                <SkeletonText width='140px' />
                <article>
                  <SkeletonText width='80px' />
                  <TagSkeleton />
                </article>
              </Tile>
              <Tile className='service bx--tile'>
                <SkeletonText width='140px' />
                <article>
                  <SkeletonText width='80px' />
                  <TagSkeleton />
                </article>
              </Tile>
            </div>
          </div>
        </article>
      </div>
    )
  }
}

class ListServices extends Component {
  render () {
    return (
      <div className='App-content'>
        <article className='App-content_content'>
          {
            Object.keys(this.props.services).map(namespace => {
              const services = this.props.services[namespace]

              return (
                <div>
                  <h2 className='namespace-title'>{namespace}</h2>
                  <h3 className='namespace-subtitle'>Dependencies</h3>
                  <div className='namespace'>
                    {
                      services.dependencies.length > 0 ?
                      <>
                        {
                          services.dependencies.map(service => {
                            return (
                              <Tile className='service'>
                                <h3 className='service-title'>{service.name}</h3>
                                <div className='tags'>
                                  {
                                    service.pods.map(pod => {
                                      return (
                                        <TooltipIcon tooltipText={pod.message ? pod.message : pod.name} >
                                          <Tag style={{ marginLeft: 0, marginRight: '0.4em' }} type={pod.status === 'Running' ? 'green' : 'red'}>{pod.status}</Tag>
                                        </TooltipIcon>
                                      )
                                    })
                                  }
                                </div>
                              </Tile>
                            )
                          })
                        }
                      </>
                      : <TooltipDefinition className='empty' tooltipText='There is no need for dependencies for this project or dependencies have not been found.'>No dependencies</TooltipDefinition>
                      }
                  </div>
                  <h3 className='namespace-subtitle'>Deployments</h3>
                  <div className='namespace'>
                    {
                      services.deployments.map(service => {
                        return (
                          <Tile className='service'>
                            <h3 className='service-title'>{service.name}</h3>
                            <Link href={service.path}>{service.path.replace('http://', '').replace('https://', '').replace(/\/$/, '')}</Link>
                            <div className='tags'>
                              {
                                service.pods.map(pod => {
                                  return (
                                    <TooltipIcon align='start' tooltipText={pod.message ? pod.message : pod.name} >
                                      <Tag style={{ marginLeft: 0, marginRight: '0.4em' }} type={pod.status === 'Running' ? 'green' : 'red'}>{pod.status}</Tag>
                                    </TooltipIcon>
                                  )
                                })
                              }
                            </div>
                          </Tile>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </article>
      </div>
    )
  }
}

export default App
